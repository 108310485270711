import { Player } from "./backend";
import { PlayerStats } from "./PlayerStats";

interface PlayerOverviewProps {
  player: Player,
  onClick: (playerId: number) => void,
  selected: boolean
}

export function PlayerOverview(props: PlayerOverviewProps) {
  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        justifyContent: 'space-between',
        padding: 10,
        // color: '#5f9481',
        color: '#538271',
        backgroundColor: 'white'
      }} onClick={() => props.onClick(props.player.id)}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '5vh',
          flexDirection: 'row',
          fontSize: 20,
          paddingLeft: '1em'
        }}>
          <div style={{ width: '2em' }}>{props.player.number}</div> {props.player.first_name} {props.player.last_name}
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '5vh',
          flexDirection: 'row',
          fontSize: 18,
          paddingRight: '1em'
        }}>
          {props.selected ? 'v' : '<'}
        </div>
      </div>
      {props.selected ? <PlayerStats player={props.player} /> : <></>}
    </div >
  );
}
