import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BuyPlayer from './BuyPlayer';
import { CreateAccount } from './CreateAccount';
import CreateTeam from './CreateTeam';
import Landing from './Landing';
import Layout from './Layout';
import { Login } from './login';
import Portfoljen from './Portfoljen';
import ScoreBoard from './ScoreBoard';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<Layout />}>
          <Route path="/portfoljen" element={<Portfoljen />} />
          <Route path="/buy_player" element={<BuyPlayer />} />
          <Route path="/scoreboard" element={<ScoreBoard />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/create_account" element={<CreateAccount />} />
        <Route path="/create_team" element={<CreateTeam />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
