export const BASE_URL = "https://fantasibandy.com:3000";
export const ORANGE = '#ff8103';
export const BLUE = '#5f9481';

export interface Order {
  type: 'sell' | 'buy',
  price: number,
  player_name: string,
  team: string
  player_id: number
};
