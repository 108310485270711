import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, signUp } from "./backend";
import { ApiError } from "./utils";

export function CreateAccount() {
  const [validationError, setValidationError] = useState<null | string>(null);
  const navigate = useNavigate();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement).entries();
    const loginInfo = Object.fromEntries(formData);

    if ((loginInfo.username as string).length === 0) {
      setValidationError("Username cannot be blank");
      return;
    }
    if ((loginInfo.password as string).length <= 3) {
      setValidationError("Password too short");
      return;
    }
    const email = loginInfo.email as string;
    if (email.length < 5) {
      setValidationError("Email too short");
      return;
    }

    (async () => {
      try {
        await signUp(loginInfo.username as string, loginInfo.password as string);
        let response = await signIn(loginInfo.username as string, loginInfo.password as string);
        localStorage.setItem("token", response.token);
        navigate('/');
      } catch (e) {
        if (e instanceof ApiError) {
        } else if (e instanceof ApiError) {
          if (e.code === 409) {
            setValidationError("User already exists");
          } else if (e.code === 401) {
            setValidationError("Invalid username or password");
          } else {
            setValidationError("Authorization failed for unknown reason...");
          }
        }
      }

    })();
  };

  return (
    <form method='post' onSubmit={onSubmit}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        height: '100vh',
        backgroundColor: '#5f9481'
      }}>
        <div style={{
          fontSize: 50,
          marginTop: 50,
          marginBottom: 50,
          color: '#ff8103'
        }}>
          Fantasibandy
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 100,
        }}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: 50 }}>
            <img src="logo.gif" alt="" style={{ width: 180, borderRadius: 90 }} />
          </div>
          <input type='text' placeholder='Användarnamn' name='username'
            style={{ fontSize: 22, marginBottom: 10, padding: 10, borderRadius: 15, border: '1px solid rgba(27, 31, 35, .15)' }}
          />
          <input type='password' placeholder='Lösenord' name='password'
            style={{ fontSize: 22, marginBottom: 10, padding: 10, borderRadius: 15, border: '1px solid rgba(27, 31, 35, .15)' }}
          />
          <input type='email' placeholder='E-post' name='email'
            style={{ fontSize: 22, marginBottom: 10, padding: 10, borderRadius: 15, border: '1px solid rgba(27, 31, 35, .15)' }}
          />
          <button type='submit' name='login' style={{
            fontSize: 22,
            padding: 10,
            marginBottom: 10,
            borderRadius: 15,
            appearance: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: 'rgba(27, 31, 35, .1) 0 0px 0',
            border: '1px solid rgba(27, 31, 35, .15)',
            backgroundColor: '#ff8103'
          }}>
            Skapa konto
          </button>
          {validationError ? <div style={{ color: 'red' }}> {validationError} </div> : <></>}
        </div>
      </div>
    </form>
  );
}
