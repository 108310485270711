import { useCallback, useEffect, useState } from 'react';
import { fetchTeam, fetchTeamStats, Player, Team } from './backend';
import { BLUE } from './constants';
import { PlayerOverview } from './PlayerOverview';

function Portfoljen() {
  const [teamName, setTeamName] = useState<string>();
  const [teamGoals, setTeamGoals] = useState<number>(0);
  const [players, setPlayers] = useState<Player[]>();
  const [selectedPlayer, setSelectedPlayer] = useState<number | null>(null);
  const [funds, setFunds] = useState<number>(0);

  const updateTeam = (team: Team) => {
    setTeamName(team.name);
    setPlayers(team.players);
    setFunds(team.money);
  }

  const syncWithBackend = useCallback(async () => {
    try {
      let team = await fetchTeam();
      updateTeam(team);
      let stats = await fetchTeamStats();
      setTeamGoals(stats.goals);
    } catch (e) {
      console.error("Handle error!");
    }
  }, []);

  useEffect(() => {
    syncWithBackend();
  }, [syncWithBackend])

  const onPlayerClick = (playerId: number) => {
    setSelectedPlayer(old => {
      if (old === playerId) {
        return null;
      } else {
        return playerId;
      }
    });
  }

  const renderPlayerList = (playerList: Player[]) => {
    return playerList
      .map((player) =>
        <PlayerOverview
          player={player}
          selected={selectedPlayer === player.id}
          onClick={onPlayerClick}
        />);
  };

  return (
    <div>
      <div style={{
        backgroundColor: BLUE,
        width: '100%',
        height: 100,
        borderTop: '1px solid white',
        display: 'flex',
        flexDirection: 'row'
      }}>
        <div style={{
          borderRight: '1px solid white',
          width: '50%',
          fontSize: 25,
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}>
          <div style={{paddingLeft: '1em'}}>{teamName}</div>
        </div>
        <div style={{
          borderRight: '1px solid white',
          width: '50%',
          fontSize: 25,
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}>
          <div style={{paddingLeft: '1em'}}>Mål: {teamGoals}</div>
          <div style={{paddingLeft: '1em'}}>{funds} SEK</div>
        </div>
      </div>
      {players ? renderPlayerList(players) : (<></>)}
    </div>);
}

export default Portfoljen;
