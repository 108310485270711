import { useCallback, useEffect, useState } from 'react';
import { getScoreBoard, ScoreBoardEntry } from './backend';

function ScoreBoard() {
  const [scoreBoard, setScoreBoard] = useState<ScoreBoardEntry[]>([]);

  const syncWithBackend = useCallback(async () => {
    try {
      const sb = await getScoreBoard();
      setScoreBoard(sb);
      console.log(sb);
    } catch (e) {
      console.error("Handle error!");
    }
  }, []);

  useEffect(() => {
    syncWithBackend();
  }, [syncWithBackend])


  return (
    <div>
      {scoreBoard.map(sb => {
        return (
          <>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottomColor: 'gray',
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
              height: '7vh',
              color: '#538271',
              fontSize: 20
            }}>
              <div style={{ paddingLeft: '1em' }}>
                {sb.team_name}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ paddingRight: '1em' }}>
                  Goals: {sb.goals}
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>);
}

export default ScoreBoard;
