import { placeBuyOrder } from "./backend";
import { Order } from "./constants";

interface PlayerOrderProps {
  order: Order,
  onBuy: () => void;
}

export function PlayerOrder(props: PlayerOrderProps) {
  const onBuyClick = async () => {
    await placeBuyOrder(props.order.player_id, props.order.price);
    props.onBuy();
  };

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        justifyContent: 'space-between',
        padding: 10,
        color: '#538271',
        backgroundColor: 'white'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '5vh',
          flexDirection: 'row',
          fontSize: 20,
          paddingLeft: '0.5em',
        }}>
          <div>{props.order.player_name}</div>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '5vh',
          flexDirection: 'row',
          fontSize: 18,
        }}>
          <button onClick={onBuyClick} style={{
            fontSize: 14,
            padding: 5,
            borderRadius: 10,
            appearance: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: 'rgba(27, 31, 35, .1) 0 0px 0',
            border: '1px solid rgba(27, 31, 35, .15)',
            backgroundColor: '#ff8103'
          }}>
            Köp {props.order.price} SEK
          </button>
        </div>
      </div>
    </div >
  );
}
