import { BASE_URL } from "./constants";

export class ApiError extends Error {
  code: number
  constructor(message: string, code: number) {
    super(message);
    this.name = "ApiError";
    this.code = code;
  }
}

export const fetchWithAuth = async <T>(route: string, config: RequestInit): Promise<T> => {
  const token = localStorage.getItem('token') as string;

  if (!token) {
    console.error("Tried to call private API route: " + route + " while not logged in.");
    throw new Error("Tried to call private API route: " + route + " while not logged in.");
  }

  config.headers = { ...config.headers, 'Authorization': 'Bearer ' + token };

  if (config.body && config.method === 'post') {
    config.headers = { ...config.headers, 'content-type': 'application/json' };
  }

  const resp = await fetch(BASE_URL + route, config);

  if (resp.status > 299 || resp.status < 200) {
    throw new ApiError(await resp.text(), resp.status);
  }

  return await resp.json();
}

export const fetchWithoutAuth = async <T>(route: string, config: RequestInit): Promise<T> => {
  if (config.body && config.method === 'post') {
    config.headers = { ...config.headers, 'content-type': 'application/json' };
  }

  const resp = await fetch(BASE_URL + route, config);

  if (resp.status > 299 || resp.status < 200) {
    throw new ApiError(await resp.text(), resp.status);
  }

  return await resp.json();
}
