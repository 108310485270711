import { BASE_URL } from "./constants";
import { ApiError, fetchWithAuth, fetchWithoutAuth } from "./utils";

export interface Player {
  id: number,
  first_name: string,
  last_name: string,
  number: number,
  real_team: string
}

interface PlayerSellOrder {
  price: number,
  player_id: number
}

interface PlayerBuyOrder {
  price: number,
  player_id: number
}

export interface ScoreBoardEntry {
  team_name: string,
  money: number,
  goals: number
}

// TODO: Figure out serialized schema of OrderStatus response
// interface OrderStatus {
// }

export const placeBuyOrder = async (player_id: number, price: number): Promise<any> => {
  return await fetchWithAuth(
    `/api/user/team/player/${player_id}/buy`,
    { method: 'post', body: JSON.stringify({ price: price }) },
  );
}

export const placeSellOrder = async (player_id: number, price: number): Promise<any> => {
  return await fetchWithAuth(
    `/api/user/team/player/${player_id}/sell`,
    { method: 'post', body: JSON.stringify({ price: price }) },
  );
}

export const getSellOrders = async (): Promise<PlayerSellOrder[]> => {
  return await fetchWithoutAuth('/api/player/sellorders', { method: 'get' });
}

export const getBuyOrders = async (): Promise<PlayerBuyOrder[]> => {
  return await fetchWithoutAuth('/api/player/buyorders', { method: 'get' });
}

export const getPlayer = async (player_id: number): Promise<Player> => {
  return await fetchWithoutAuth(`/api/player/${player_id}`, { method: 'get' });
}

export const listPlayers = async (): Promise<Player[]> => {
  return await fetchWithoutAuth("/api/player", { method: 'get' });
}

export interface LoginResponse {
  token: string;
};

export interface SignUpResponse {
  user_id: string;
  username: string;
};

export interface User {
  id: number,
  username: string
}
export const signIn = async (username: string, password: string): Promise<LoginResponse> => {
  const body = JSON.stringify({ "username": username, "password": password });
  const resp = await fetch(BASE_URL + '/api/login', {
    method: 'post',
    body: body,
    headers: { 'content-type': 'application/json' }
  });
  if (resp.status > 299 || resp.status < 200) {
    throw new ApiError(await resp.text(), resp.status);
  }
  return await resp.json();
}

export const signUp = async (username: string, password: string): Promise<SignUpResponse> => {
  const body = JSON.stringify({ "username": username, "password": password });
  const resp = await fetch(BASE_URL + '/api/user', {
    method: 'post',
    body: body,
    headers: { 'content-type': 'application/json' }
  });
  if (resp.status > 299 || resp.status < 200) {
    throw new ApiError(await resp.text(), resp.status);
  }
  return await resp.json();
}

export const fetchUser = async (): Promise<User> => {
  return await fetchWithAuth('/api/user', {})
}

export interface Team {
  id: number,
  name: string,
  players: Player[],
  money: number
};

export interface TeamStats {
  goals: number;
};


export interface PlayerStatsType {
  goals_for_team: number;
};

export const fetchTeamStats = async (): Promise<TeamStats> => {
  return await fetchWithAuth('/api/user/team/stats', {})
}

export const fetchTeam = async (): Promise<Team> => {
  return await fetchWithAuth('/api/user/team', {});
}

export const fetchPlayerStats = async (playerId: number): Promise<PlayerStatsType> => {
  return await fetchWithAuth('/api/user/team/player/' + playerId, {});
}

export const createTeam = async (name: string): Promise<Team> => {
  const body = JSON.stringify({ "name": name });
  return await fetchWithAuth('/api/user/team', { method: 'post', body: body });
}

export const hireRandomPlayer = async (): Promise<Team> => {
  return await fetchWithAuth('/api/user/team/hire', { method: 'post' });
}

export const getScoreBoard = async (): Promise<ScoreBoardEntry[]> => {
  return await fetchWithoutAuth('/api/user/team/scoreboard', { method: 'get' });
}

