import { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { createTeam } from "./backend";

function CreateTeam() {
  const navigate = useNavigate();
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement).entries();
    const teamInfo = Object.fromEntries(formData);

    (async () => {
      try {
        const team = await createTeam(teamInfo.team_name as string);
        console.log(team);
        navigate('/portfoljen');
      } catch (e) {
        console.error("failed to create team");
      }
    })();
  };

  return (
    <form method='post' onSubmit={onSubmit}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        height: '100vh',
        backgroundColor: '#5f9481'
      }}>
        <div style={{
          fontSize: 50,
          marginTop: 50,
          marginBottom: 50,
          color: '#ff8103'
        }}>
          Fantasibandy
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 100,
        }}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: 50 }}>
            <img src="logo.gif" alt="" style={{ width: 180, borderRadius: 90 }} />
          </div>
          <input type='text' placeholder='Klubbnamn' name='team_name'
            style={{ fontSize: 22, marginBottom: 10, padding: 10, borderRadius: 15, border: '1px solid rgba(27, 31, 35, .15)' }}
          />
          <button type='submit' name='create' style={{
            fontSize: 22,
            padding: 10,
            marginBottom: 10,
            borderRadius: 15,
            appearance: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: 'rgba(27, 31, 35, .1) 0 0px 0',
            border: '1px solid rgba(27, 31, 35, .15)',
            backgroundColor: '#ff8103'
          }}>
            Skapa Klubb
          </button>
        </div>
      </div>
    </form>
  );
}

export default CreateTeam;
