import { useCallback, useEffect, useState } from 'react';
import { fetchTeam, getPlayer, getSellOrders } from './backend';
import { BLUE, Order } from './constants';
import { PlayerOrder } from './PlayerOrder';


function BuyPlayer() {
  const [orders, setOrders] = useState<Order[]>([]);

  const [funds, setFunds] = useState<number | null>(null);

  const syncWithBackend = useCallback(async () => {
    try {
      setFunds((await fetchTeam()).money);
      const orderResp = await getSellOrders();
      const newOrders = await Promise.all(orderResp.map(async o => {
        const player_details = await getPlayer(o.player_id);
        const order: Order = {
          type: 'sell',
          price: o.price,
          player_name: player_details.first_name + ' ' + player_details.last_name,
          team: 'TBD',
          player_id: o.player_id
        };
        return order;
      }));
      setOrders(newOrders);
    } catch (e) {
      console.error("TODO: Handle error!");
    }
  }, []);

  useEffect(() => {
    syncWithBackend();
  }, [syncWithBackend])

  return (
    <>
      <div style={{
        backgroundColor: BLUE,
        width: '100%',
        height: 100,
        borderTop: '1px solid white',
        display: 'flex',
        flexDirection: 'row'
      }}>
        <div style={{
          borderRight: '1px solid white',
          width: '100%',
          fontSize: 25,
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}>
          <div style={{ paddingLeft: '1em' }}>{funds || 0} SEK</div>
        </div>
      </div>
      <div>
        {orders?.map(order => <PlayerOrder order={order} onBuy={syncWithBackend} />)}
      </div>
    </>
  );
}

export default BuyPlayer;
