import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from "react";
import { BLUE, ORANGE } from "./constants";

function Layout() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location.pathname);
  const currentPage = () => {
    if (location.pathname === '/portfoljen') {
      return 'Portföljen';
    } else if (location.pathname === '/buy_player') {
      return 'Bandybörsen';
    } else if (location.pathname === '/scoreboard') {
      return 'Tabellen';
    } else {
      return 'ERROR PAGE';
    }
  }

  const logout = () => {
    localStorage.clear();
    navigate('/');
  }

  return (<div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
    <header style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: '10vh',
      backgroundColor: ORANGE,
      paddingLeft: 20,
      paddingRight: 20,
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 30,
      zIndex: 10
    }}>
      <RxHamburgerMenu
        onClick={() => setMenuOpen(old => !old)}
        style={{ zIndex: 12, cursor: 'pointer' }}
      />
      {currentPage()}
    </header>

    <div style={{
      position: 'fixed',
      top: '10vh',
      left: 0,
      bottom: 0,
      borderRight: '1px solid white',
      width: '80vw',
      transform: menuOpen ? 'translateX(0)' : 'translateX(-100%)',
      opacity: menuOpen ? 1 : 0,
      transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
      zIndex: 11,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: BLUE,
      alignItems: 'start'
    }}>
      <div style={{ width: '100%', borderBottom: '1px solid white' }} />
      <div
        onClick={
          () => {
            navigate('/portfoljen');
            setMenuOpen(false);
          }
        }
        style={{
          fontSize: 22,
          padding: '0.8em',
          color: 'white',
          cursor: 'pointer',
          width: '100%'
        }}>
        Portföljen
      </div>
      <div style={{ width: '100%', borderBottom: '1px solid white' }} />
      <div
        onClick={
          () => {
            navigate('/buy_player');
            setMenuOpen(false);
          }
        }
        style={{
          fontSize: 22,
          padding: '0.8em',
          color: 'white',
          cursor: 'pointer',
          width: '100%'
        }}>
          Bandybörsen
      </div>
      <div style={{ width: '100%', borderBottom: '1px solid white' }} />
      <div
        onClick={
          () => {
            navigate('/scoreboard');
            setMenuOpen(false);
          }
        }
        style={{
          fontSize: 22,
          padding: '0.8em',
          color: 'white',
          cursor: 'pointer',
          width: '100%'
        }}>
        Tabellen
      </div>
      <div style={{ width: '100%', borderBottom: '1px solid white' }} />
      <div
        onClick={() => logout()}
        style={{
          fontSize: 22,
          padding: '0.8em',
          color: 'white',
          cursor: 'pointer',
          width: '100%'
        }}>
        Logga ut
      </div>
      <div style={{ width: '100%', borderBottom: '1px solid white' }} />

    </div>

    <div style={{
      position: 'absolute',
      top: '10vh',
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 0
    }}>
      <Outlet />
    </div>
  </div>);
}

export default Layout;
