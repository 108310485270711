import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTeam, fetchUser } from './backend';

function Landing() {
  const navigate = useNavigate();

  const handleLanding = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token === null) {
      navigate('/login');
    } else {
      try {
        await fetchUser();
        try {
          await fetchTeam();
          navigate('/portfoljen');
        } catch (e) {
          navigate('/create_team');
        }
      } catch (e) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    handleLanding();
  }, [navigate, handleLanding]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
      height: '100vh',
      backgroundColor: '#5f9481'
    }}>
      <div style={{
        fontSize: 50,
        marginTop: 50,
        marginBottom: 50,
        color: '#ff8103'
      }}>
        Fantasibandy
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 100,
      }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingBottom: 50 }}>
          <img src="logo.gif" alt="" style={{ width: 180, borderRadius: 90 }} />
        </div>
      </div>
    </div>
  );
}

export default Landing;
